import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import "snapsvg-cjs"

Vue.use(Vuex)

import text from './text'

function apiUrl(route) {

    var url = window.location.href;
    var subDomain = 'www';

    if(url.includes('localhost')) {
        subDomain = 'dev';
        // subDomain = 'beta';
    }
    if(url.includes('ssbuilder-test')) {
        subDomain = 'test';
    }
    if(url.includes('ssbuilder-staging')) {
        subDomain = 'beta';
    }

    var apiUrl = 'https://' + subDomain + '.safetysigns4less.co.uk';
    return apiUrl + route;

}

export default new Vuex.Store({
    state: {
        options: {
            materials: [],
            orientations: [
                { code: 'Portrait', label: 'Portrait', width: 200, height: 300 },
                { code: 'Landscape', label: 'Landscape', width: 300, height: 200 }
            ],
            sizes: [],
            symbols: []
        },
        selected: {
            material: '',
            orientation: 'landscape',
            size: 'size_03',
            symbol: '',
            symbolSize: 100
        },
        loading: false,
        error: null,
        previewSignSizeRatio: 0.5,
        loadedSymbol: '',
        svgWidth: 488,
        svgHeight: 400,
        lifePreviewOpen: false
    },
    mutations: {
        setMaterialOptions(state, materials) {
            var mArray = [];
            materials.forEach((m) => {
                mArray.push({
                    id: m.id,
                    label: m.material,
                    description: m.description
                });
            });
            state.options.materials = mArray;
        },
        setSizeOptions(state, sizes) {
            state.options.sizes = sizes;
        },
        setSelectedMaterial(state, materialId) {
            state.selected.material = materialId;
        },
        setDefaultSelectedMaterial(state) {
            if(state.options.materials.length > 0) {
                state.selected.material = state.options.materials[0].id;
            }
        },
        setSelectedOrientation(state, code) {
            state.selected.orientation = code;
        },
        setSelectedSize(state, id) {
            state.selected.size = id;
        },
        setDefaultSelectedSize(state) {
            var sizes = state.options.sizes.filter((s) => s.orientation === state.selected.orientation);
            if(sizes.length > 0) {
                state.selected.size = sizes[0].id;
            }
        },
        setSymbolOptions(state, symbols) {
            state.options.symbols = symbols;
        },
        setDefaultSymbol(state) {

        },
        setSelectedSymbol(state, symbol) {
            state.selected.symbol = symbol;
        },
        setLoadedSymbol(state, svg) {
            state.loadedSymbol = svg;
        },
        setSelectedSymbolSize(state, size) {
            state.selected.symbolSize = size;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setError(state, e) {
            state.error = e;
        },
        setLifePreviewOpen(state, open) {
            state.lifePreviewOpen = open;
        }
    },
    actions: {
        async getMaterials({state, commit}) {
            var url = apiUrl('/api/v2/custom-signs/materials');
            commit('setError', null);
            return await axios.get(url)
                .then((res) => {
                    commit('setMaterialOptions', res.data);
                    commit('setDefaultSelectedMaterial');
                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        },
        async getSizes({state, commit}, material_id) {
            var url = apiUrl('/api/v2/custom-signs/sizes/' + material_id);
            commit('setError', null);
            return await axios.get(url)
                .then((res) => {
                    commit('setSizeOptions', res.data);
                    commit('setDefaultSelectedSize');
                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        },
        async getSymbols({state, commit}) {
            var url = apiUrl('/api/v2/custom-signs/symbols');
            commit('setError', null);
            return await axios.get(url)
                .then((res) => {
                    commit('setSymbolOptions', res.data);
                    commit('setDefaultSymbol');
                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        },
        async submitForm({state, commit}, svgString) {
            var url = apiUrl('/api/v2/custom-signs/submit-builder-form');

            var formData = new FormData();
            formData.append('sign_svg', svgString);

            commit('setLoading', true);
            commit('setError', null);
            return await axios({
                method: 'post',
                url: url,
                headers: {
                    headers: {
                        'content-type': 'application/json',
                    },
                },
                data: formData
            })
                .then((res) => {

                    if(res.data.error) {
                        commit('setError', res.data.error);
                    }

                    if(res.data) {
                        window.location.replace(res.data.redirect);
                    }

                    commit('setLoading', false);
                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        },
        async checkProfanity({state, commit}, textAreas) {
            var url = apiUrl('/api/v2/custom-signs/profanity-check');
            commit('setLoading', true);

            var formData = new FormData();

            textAreas.forEach((ta) => {
                ta.lines.forEach((line, i) => {
                    console.log('LINE ', line);
                    formData.append('phrases[' + i + ']', line);
                });
            });

            commit('setError', null);
            var things = axios({
                method: 'post',
                url: url,
                headers: {
                    headers: {
                        'content-type': 'application/json',
                    },
                },
                data: formData
            });

            return things;
        }
    },
    getters: {
        apiUrl(state) {
            return apiUrl('');
        },
        mode(state) {
            // return  this.$route.query );
        },
        sizeOptions(state) {
            return state.options.sizes.filter((s) => s.orientation === state.selected.orientation);
        },
        selectedMaterial(state) {
            return state.options.materials.find((m) => m.id === state.selected.material);
        },
        selectedSize(state) {
            return state.options.sizes.find((s) => s.id === state.selected.size);
        },
        selectedSymbol(state) {

            if(!state.selected.symbol) {
                return null;
            }

            var ids = state.selected.symbol.split('-');
            var groupId = ids[0];
            var symbolId = ids[1];
            var group = state.options.symbols.find((g) => g.id === groupId);

            return group.signs.find((s) => s.id === symbolId);

        },
        signBackgroundColor(state) {
            if(state.selected.material == 5) {
                return '#f6ffca';
            }
            return '#fff';
        },
        previewSignDimensions(state, getters) {
            var size = getters.selectedSize;
            if(!size) {
                return { width: 0, height: 0 };
            }
            return {
                width: size.width * state.previewSignSizeRatio,
                height: size.height * state.previewSignSizeRatio
            }
        }
    },
    modules: {
        text
    }
})
