<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

    var bootstrap = require('../node_modules/bootstrap/dist/css/bootstrap.min.css');

</script>

<style lang="scss">

    // this is the font used in the old version

    /*@font-face{*/
    /*    font-family:"Helvetica LT W05 Bold";*/
    /*    src:url("/assets/product-builders/ProductBuilder/fonts/helvetica/0a94887d-a727-44b5-a288-2c7b1882d779.eot?#iefix");*/
    /*    src:url("/assets/product-builders/ProductBuilder/fonts/helvetica/0a94887d-a727-44b5-a288-2c7b1882d779.eot?#iefix") format("eot"),*/
    /*    url("/assets/product-builders/ProductBuilder/fonts/helvetica/f8217c53-d092-4301-96c4-c493faf448db.woff2") format("woff2"),*/
    /*    url("/assets/product-builders/ProductBuilder/fonts/helvetica/ee50e0a9-594b-4771-9d28-d2f79db50a39.woff") format("woff"),*/
    /*    url("/assets/product-builders/ProductBuilder/fonts/helvetica/45f49af2-f30e-4a80-bd9d-53e3a42b3285.ttf") format("truetype");*/
    /*}*/

    .container {
        max-width: 984px;
    }

    p {
        font-size: 14px;
        /*font-family: 'Helvetica Neue LT Pro 75 Bold';*/
    }

    .error {
        position: fixed;
        background: #ca4343;
        top: 0px;
        width: 100%;
        left: 0px;
        text-align: center;
        color: #fff;
        padding: 20px;
    }

</style>
