<template>

    <div>

        <div class="symbol-option-box">

            <p class="title">Choose a Symbol</p>

            <div class="symbols">
                <div class="symbol-group" v-for="group in $store.state.options.symbols">
                    <p class="symbol-group__title">{{ group.name }}</p>
                    <ul>
                        <li v-for="symbol in group.signs">
                            <img :src="symbolUrl(symbol)"
                                 loading="lazy"
                                 @click="$emit('symbolSelected', group.id + '-' + symbol.id)" />
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="symbol-option-box">

            <p class="mt-3 title">Choose symbol size</p>

            <ul class="sizes-ul" v-if="$store.getters.selectedSymbol">
                <li v-for="size in sizes" @click="$emit('sizeSelected', size)">
                    <img :src="symbolUrl($store.getters.selectedSymbol)" :style="{ width: getIconPreviewWidth(size) }" />
                    <span>{{ size }}%</span>
                </li>
            </ul>

        </div>

    </div>

</template>

<script>

    export default {
        name: 'Symbols',
        data() {
            return {
                iconPreviewWidth: 80,
                sizes: [60, 70, 80, 90, 100]
            }
        },
        methods: {
            getIconPreviewWidth(size) {
                size = size / 100;
                var pxSize = (this.iconPreviewWidth * size);
                return pxSize + 'px';
            },
            symbolUrl(symbol) {
                if(this.$route.query.dev) {
                    return symbol.preview_image_url;
                }
                var baseUrl = this.$store.getters.apiUrl;
                return baseUrl + '/proxy?url=' + symbol.preview_image_url;
            }
        }
    }

</script>

<style lang="scss">

    .title {
        font-size: 22px;
    }

    .symbols {
        background: #fff;
        padding: 8px;
        border-radius: 2px;
        max-height: 250px;
        overflow-y: scroll;
    }

    .symbol-option-box {
        ul {
            list-style: none;
            margin: 0px 0px 16px 0px;
            padding: 0px;
            li {
                display: inline-block;
                margin-right: 8px;
                margin-bottom: 8px;
                img {
                    cursor: pointer;
                }
            }
        }
    }

    .symbol-group {
        &__title {
            font-weight: 600;
            text-transform: uppercase;
        }
        ul {
            li {
                width: 50px;
            }
        }
    }

    .sizes-ul {
        li {
            text-align: center;
        }
        img {
            display: block;
        }
    }

</style>