export default {

    textHeight: 10,
    textSpacing: 5,

    getTextPosX(panelAttr, spacing, depth) {
        var x = panelAttr.x + (panelAttr.width / 2);
        var y = panelAttr.y + panelAttr.height + spacing + depth + this.textHeight + this.textSpacing;
        return {
            x: x,
            y: y
        };
    },

    getTextPosY(panelAttr, spacing, depth) {
        var x = panelAttr.x + panelAttr.width + spacing + depth + this.textSpacing + 16;
        var y = panelAttr.y + ((panelAttr.height - this.textHeight) / 2);
        return {
            x: x,
            y: y
        };
    }

}