import Vue from 'vue'
// import FontSizeCalc from '../helpers/FontSizeCalc';
//
var defaultFontSize = 22;
// var fontCalc = new FontSizeCalc(defaultFontSize);

let state = {
    areas: [
        {
            text: '',
            alignment: 'center',
            fontSize: defaultFontSize,
            position: 0,

        }
    ]
};

const mutations = {
    addTextArea(state) {
        state.areas.push({
            text: '',
            alignment: 'center',
            fontSize: defaultFontSize,
            position: 0
        });
    },
    removeTextArea(state, index) {
        if(state.areas.length > 1) {
            state.areas.splice(index, 1);
        }
    },
    setText(state, data) {
        var obj = state.areas[data.index];
        obj.text = data.text;
        Vue.set(state.areas, data.index, obj);
    },
    setAlignment(state, data) {
        var obj = state.areas[data.index];
        obj.alignment = data.alignment;
        Vue.set(state.areas, data.index, obj);
    },
    setTextPosition(state, data) {
        var obj = state.areas[data.index];
        obj.position = ( data.up ? obj.position - 1 : obj.position + 1 );
        Vue.set(state.areas, data.index, obj);
    },
    setFontSize(state, data) {
        var obj = state.areas[data.index];
        obj.fontSize = ( data.larger ? obj.fontSize - 1 : obj.fontSize + 1 );
        Vue.set(state.areas, data.index, obj);
    }
};

const getters = {
    textAreas(state) {
        var areas = state.areas;
        areas.forEach((a, i) => {
            areas[i].lines = a.text.split("\n");
        });
        return areas;
    },
    text(state) {
        return function(index) {
            return state.areas[index].text;
        }
    }
};

export default {
    state,
    mutations,
    getters
}