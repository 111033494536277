<template>

    <div class="sign-attr">
        <div class="sign-attr__header">
            {{ title }}
        </div>
        <div class="sign-attr__body">
            <slot></slot>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'SignAttribute',
        props: ['title']
    }

</script>

<style lang="scss">

    .sign-attr {
        &__header {
            background: #1159a2;
            color: #fff;
            padding: 8px 16px;
        }
        &__body {
            background: #e4e4e4;
            padding: 16px;
            overflow: hidden;
        }
    }

</style>