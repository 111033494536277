class SymbolCalculator {

    getSymbolSize(panelWidth, panelHeight, sizePercentage) {
        var multiplyer = sizePercentage / 100;
        var size;

        if(panelHeight > panelWidth) {
            // portrait
            size = panelWidth - (this.padding * 2);
        }else{
            // landscape
            size = panelHeight - (this.padding * 2);
        }

        return size * multiplyer;
    }

    getPanelAttr(panel) {
        return {
            height: Number(panel.attr('height')),
            width: Number(panel.attr('width')),
            x: Number(panel.attr('x')),
            y: Number(panel.attr('y'))
        };
    }

    getSymbolAttr(panelAttr, sizePercentage) {

        var size, x, y;

        // get details from panel
        // var panelAttr = this.getPanelAttr(panel);

        // set details for sign
        size = this.getSymbolSize(panelAttr.width, panelAttr.height, sizePercentage);

        console.log(size);

        if(panelAttr.height > panelAttr.width) {
            // portrait
            x = panelAttr.x + (panelAttr.width - size) / 2;
            y = panelAttr.y + this.padding;
        }else{
            // landscape
            x = panelAttr.x + this.padding;
            y = panelAttr.y + (panelAttr.height - size) / 2;
        }

        return {
            height: size,
            width: size,
            x: x,
            y: y
        };
    }

    getTextPanelAttr(panelAttr, sizePercentage) {

        var width, height, x, y;

        // get details from panel
        // var panelAttr = this.getPanelAttr(panel);
        var symbolSize = this.getSymbolSize(panelAttr.width, panelAttr.height, sizePercentage);

        if(panelAttr.height > panelAttr.width) {
            // portrait
            width = panelAttr.width - (this.padding * 2);
            height = panelAttr.height - (this.padding * 2) - symbolSize - this.gap;
            x = panelAttr.x + this.padding;
            y = panelAttr.y + this.padding + symbolSize + this.gap;
        }else{
            // landscape
            width = panelAttr.width - (this.padding * 2) - symbolSize - this.gap;
            height = panelAttr.height - (this.padding * 2);
            x = panelAttr.x + this.padding + symbolSize + this.gap;
            y = panelAttr.y + this.padding;
        }

        return {
            width: width,
            height: height,
            x: x,
            y: y,
            r: 5
        }

    }

    // setSize(size) {
    //     this.symbolSize = Number(size);
    // }

    constructor(padding, gap) {
        this.padding = Number(padding);
        this.gap = Number(gap);
        // this.symbolSize = Number(symbolSize);
    }

}

export default SymbolCalculator;