class FontSizeCalc {

    _setFont() {
        this.context.font = this.fontWeight + " " + this.fontSize + "px " + this.fontFamily;
    }

    _getWidth(text) {
        var metrics = this.context.measureText(text);
        return Math.floor(metrics.width);
    }

    _adjustFontSize(text) {
        var attempts = 0;
        var maxAttempts = 40;
        var textWidth = this._getWidth(text);
        while( textWidth > this.maxWidth && attempts < maxAttempts ) {
            // update
            this._setFont();
            this.fontSize--;
            attempts++;
            textWidth = this._getWidth(text);
        }
    }

    getSize(text, fontSize, maxWidth) {
        this.maxWidth = maxWidth;
        this.fontSize = fontSize;
        this._setFont();
        this._adjustFontSize(text);
        return this.fontSize;
    }

    constructor(fontFamily, fontWeight) {
        // re-use canvas object for better performance
        this.canvas = this.canvas || (this.canvas = document.createElement("canvas"));
        this.context = this.canvas.getContext("2d");
        this.fontFamily = fontFamily;
        this.fontWeight = fontWeight;
        this.fontSize = 28;
    }

}

export default FontSizeCalc;