<template>

    <text :fill="color" :style="style" :x="textPos.x" :y="textPos.y" style="font-family: 'Helvetica'; font-weight: 600;">
        <tspan v-for="(line, i) in lines" :x="textPos.x" :y="tspanY(i)" :style="'font-size: ' + fontSize">{{ line }}</tspan>
    </text>

</template>

<script>

    export default {
        name: 'SvgText',
        props: ['color', 'alignment', 'position', 'lines', 'tpAttr', 'totalPrevTextHeight', 'fontSize'],
        // data() {
        //     return {
        //         fontSize: 28
        //     }
        // },
        computed: {
            style() {
                return {
                    alignmentBaseline: this.textPos.alignmentBaseline,
                    textAnchor: this.textPos.textAnchor
                };
            },
            textPos() {

                var x = this.tpAttr.x + (this.tpAttr.width / 2);
                var y = this.tpAttr.y + this.fontSize;

                var alignmentBaseline = 'auto'; // vertical align
                var textAnchor = 'auto'; // horizontal align

                switch (this.alignment) {
                    case 'left':
                        x = this.tpAttr.x;
                        alignmentBaseline = 'before-edge';
                        textAnchor = 'start';
                        break;
                    case 'center':
                        alignmentBaseline = 'after-edge';
                        textAnchor = 'middle';
                        break;
                    case 'right':
                        x = this.tpAttr.x + this.tpAttr.width;
                        alignmentBaseline = 'before-edge';
                        textAnchor = 'end';
                        break;
                    default:
                }

                return {
                    alignmentBaseline: alignmentBaseline,
                    textAnchor: textAnchor,
                    x,
                    y
                };

            }
        },
        methods: {
            tspanY(i) {
                var y = this.fontSize;
                y += (this.fontSize * (i)) + this.tpAttr.y + this.totalPrevTextHeight + this.position;
                return y;
            }
        },
        // updated() {
        //     var longest = '';
        //     this.lines.forEach((line) => {
        //         if(line.length > longest.length) {
        //             longest = line;
        //         }
        //     });
        //     this.fontSize = fontCalc.getSize(longest, this.tpAttr.width);
        // }
    }

</script>