<template>

    <div class="sign-preview" @previewStartedLoading="previewStartedLoading"
                              @previewStoppedLoading="previewStoppedLoading">

        <div>

            <svg id="the_svg">

                <defs></defs>

                <!-- Panel -->
                <rect class="svg_panel" :x="panelAttr.x" :y="panelAttr.y" :width="panelAttr.width" :height="panelAttr.height" rx="0" ry="0" :fill="background" style=""></rect>

                <!-- Ruler X -->
                <g v-if="size" class="svg_ruler">
                    <path :d="rulerX.path" :stroke="rulerColor" style="stroke-width: 1;"></path>
                    <text :x="rulerX.x" :y="rulerX.y" fill="#606060" style="font-size: 12px; alignment-baseline: middle; text-anchor: middle;">{{ size.width }} mm</text>
                </g>

                <!-- Ruler Y -->
                <g v-if="size" class="svg_ruler">
                    <path :d="rulerY.path" :stroke="rulerColor" style="stroke-width: 1;"></path>
                    <text :x="rulerY.x" :y="rulerY.y" fill="#606060" style="font-size: 12px; alignment-baseline: middle; text-anchor: middle;">{{ size.height }}</text>
                    <text :x="rulerY.x" :y="rulerY.y + 10" fill="#606060" style="font-size: 12px; alignment-baseline: middle; text-anchor: middle;">mm</text>
                </g>

                <!-- Icon -->
                <svg version="1.1"
                     v-if="size"
                     id="symbol"
                     class="svg_icon"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     :x="symbolAttr.x"
                     :y="symbolAttr.y"
                     :width="symbolAttr.width"
<!--                     :height="symbolAttr.height"-->
                     viewBox="0 0 1133.7 1133.7"
                     style="enable-background:new 0 0 1133.7 1133.7;"
                     xml:space="preserve">
                </svg>

                <!-- Text Panel -->
                <rect v-if="size && symbol"
                      class="svg_text_panel"
                      :fill="symbolColor"
                      :width="textPanelAttr.width"
                      :height="textPanelAttr.height"
                      :x="textPanelAttr.x"
                      :y="textPanelAttr.y"
                      :rx="textPanelAttr.r"
                      :ry="textPanelAttr.r"></rect>

                <!-- Text -->
                <g :transform="'matrix(1,0,0,1,0,' + textY + ')'" ref="textG">
                    <SvgText v-for="(ta, i) in textAreas"
                             :color="textColor"
                             :textArea="ta"
                             :lines="ta.lines"
                             :alignment="ta.alignment"
                             :fontSize="fontSize(ta)"
                             :position="ta.position"
                             :tpAttr="textPanelAttr"
                             :totalPrevTextHeight="totalPrevTextHeight(i)" />
                </g>

            </svg>

        </div>

    </div>

</template>

<script>

    import SvgText from "../components/svgs/SvgText";

    import calc from "../helpers/PreviewCalculator";
    import RulerPathBuilder from "../helpers/RulerPathBuilder";
    import RulerCalculator from "../helpers/RulerCalculator";
    import SymbolCalculator from "../helpers/SymbolCalculator";
    import FontSizeCalc from '../helpers/FontSizeCalc';

    const fontCalc = new FontSizeCalc( "Helvetica", 600);

    const rulerSpace = 5;
    const rulerDepth = 10;
    const padding = 60;
    const symbolGap = 10;
    const signPadding = 10;

    const ruler = new RulerPathBuilder(rulerSpace, rulerDepth);
    const symCalc = new SymbolCalculator(signPadding, symbolGap);

    var sign = null;

    export default {
        name: 'SignPreview',
        props: ['compKey'],
        components: {
            SvgText
        },
        data() {
            return {
                canvas: null,
                ruler: null,
                loading: false,
                padding: padding,
                signPadding: signPadding,
                symbolGap: symbolGap,
                rulerColor: '#b7b7b7',
                rulerSpace: rulerSpace,
                rulerDepth: rulerDepth,
                rulerTextSize: 12,
                // symbolSvg: '',
                textGHeight: 0
            }
        },
        methods: {
            previewStartedLoading() {
                this.$emit('previewStartedLoading');
            },
            previewStoppedLoading() {
                this.$emit('previewStoppedLoading');
            },
            totalPrevTextHeight(index) {
                if(index === 0) {
                    return 0;
                }

                var h = 0;

                for(var i=0;i<index;i++) {
                    var textArea = this.textAreas[i];
                    h += textArea.lines.length * this.fontSize(textArea);
                }

                return h;
            },
            fontSize(textArea) {
                var longest = '';
                textArea.lines.forEach((line) => {
                    if(line.length > longest.length) {
                        longest = line;
                    }
                });
                return fontCalc.getSize(longest, textArea.fontSize, this.textPanelAttr.width);
            }
        },
        computed: {
            compKeyThing() {
                return this.compKey;
            },
            background() {
                return this.$store.getters.signBackgroundColor;
            },
            size() {
                return this.$store.getters.selectedSize;
            },
            panelAttr() {
                var dim = this.$store.getters.previewSignDimensions;
                var px = calc.getSizeInPixels(dim.width, dim.height);
                var pos = calc.getCoordsToCenter(px.width, px.height);
                return {
                    width: px.width,
                    height: px.height,
                    x: pos.x || 0,
                    y: pos.y || 0
                };
            },
            rulerX() {
                var pos = RulerCalculator.getTextPosX(this.panelAttr, this.rulerSpace, this.rulerDepth);
                return {
                    path: ruler.buildX( this.panelAttr ), // 'M60 327.5L60 337.5M60 337.5L428 337.5M428 337.5L428 327.5',
                    x: pos.x,
                    y: pos.y
                }
            },
            rulerY() {
                var pos = RulerCalculator.getTextPosY(this.panelAttr, this.rulerSpace, this.rulerDepth);
                return {
                    path: ruler.buildY( this.panelAttr ), // 'M60 327.5L60 337.5M60 337.5L428 337.5M428 337.5L428 327.5',
                    x: pos.x,
                    y: pos.y
                }
            },
            symbol() {
                return this.$store.getters.selectedSymbol;
            },
            symbolUrl() {
                var symbol = this.$store.getters.selectedSymbol;
                if(!symbol) {
                    return null;
                }
                var s3url = symbol.preview_image_url;
                var apiUrl = this.$store.getters.apiUrl;
                return apiUrl + '/proxy?url=' + s3url;
            },
            symbolColor() {
                var symbol = this.$store.getters.selectedSymbol;
                if(!symbol) {
                    return null;
                }
                return symbol.sign_color;
            },
            symbolSize() {
                return this.$store.state.selected.symbolSize;
            },
            symbolAttr() {
                return symCalc.getSymbolAttr(this.panelAttr, this.symbolSize);
            },
            textPanelAttr() {
                return symCalc.getTextPanelAttr(this.panelAttr, this.symbolSize);
            },
            textY() {
                var height = 0;
                this.textAreas.forEach((ta) => {
                    height += ta.lines.length * this.fontSize(ta);
                });
                return (this.textPanelAttr.height - height) / 2;
            },
            textColor() {
                var material = this.$store.getters.selectedMaterial;
                if(material && material.label === 'Photoluminescent') {
                    return '#f6ffca';
                }
                var symbol = this.$store.getters.selectedSymbol;
                if(!symbol) {
                    return null;
                }
                return symbol.text_color;
            },
            textAreas() {
                return this.$store.getters.textAreas;
            }
        },
        mounted() {
            calc.set(this.$store.state.svgWidth, this.$store.state.svgHeight, this.padding);
        }
    }

</script>

<style>

    .sign-preview {
        width: 100%;
        height: 400px;
        background: #efefef;
        background: -moz-linear-gradient(0deg, #f5f5f5 0%,#f1f1f1 18%,#e0e0e0 47%,#e8e8e8 56%,#d4d4d4 100%);
        background: -webkit-linear-gradient(0deg, #f5f5f5 0%,#f1f1f1 18%,#e0e0e0 47%,#e8e8e8 56%,#d4d4d4 100%);
        background: linear-gradient(0deg, #f5f5f5 0%,#f1f1f1 18%,#e0e0e0 47%,#e8e8e8 56%,#d4d4d4 100%);
    }

    #sign_svg,
    #the_svg {
        width: 488px;
        height: 400px;
    }

</style>