<template>

    <div class="summary-details">

        <div class="panel">
            <div class="row">
                <div class="col-sm-6">
                    <div class="btn btn-warning" @click="addToBasket">Add to Basket</div>
                </div>
                <div class="col-sm-6">
                    <div class="btn btn-primary" @click="showSvgPreview">Preview</div>
                </div>
            </div>
        </div>

<!--        <div class="d-flex buttons justify-content-center panel">-->
<!--            <div class="btn btn-warning mr-1">Add to Basket</div>-->
<!--            <div class="btn btn-primary ml-1">Preview</div>-->
<!--        </div>-->

        <div class="buttons justify-content-center panel">

            <div class="row">
                <div class="col-sm-6">
                    <div class="panel_white">
                        <span>Total: £165.00</span>
                    </div>
                    <div class="panel_white">
                        <p>Quantity</p>
                        <div class="d-flex">
                            <button class="m-0 mr-2" @click="decreaseQuantity(1)">-</button>
                            <input type="number" class="w-100" v-model="quantity" />
                            <button class="m-0 ml-2" @click="increaseQuantity(1)">+</button>
                        </div>
                        <div class="d-flex">
                            <button class="mr-1" @click="decreaseQuantity(10)">-10</button>
                            <button class="ml-1" @click="increaseQuantity(10)">+10</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">

                    <table>
                        <tr>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                        <tr :class="{'price_highlight': isPriceBand(1, 4)}">
                            <td>1-4</td>
                            <td>£10.95</td>
                        </tr>
                        <tr :class="{'price_highlight': isPriceBand(5, 9)}">
                            <td>5-9</td>
                            <td>£9.50</td>
                        </tr>
                        <tr :class="{'price_highlight': isPriceBand(10, 19)}">
                            <td>10-19</td>
                            <td>£8.40</td>
                        </tr>
                        <tr :class="{'price_highlight': isPriceBand(20, 99999)}">
                            <td>20+</td>
                            <td>£6.60</td>
                        </tr>
                    </table>
                    <p>Prices exclude VAT at 20%</p>

                </div>
            </div>
        </div>
        <div class="panel">
            <table>
                <tr>
                    <th>Material</th>
                    <td>Self Adhesive Vinyl</td>
                </tr>
                <tr>
                    <th>Orientation</th>
                    <td>Portrait</td>
                </tr>
                <tr>
                    <th>Size</th>
                    <td>300mm x 400mm</td>
                </tr>
                <tr>
                    <th>Compliance</th>
                    <td>iso 7010:2012</td>
                </tr>
            </table>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'Summary',
        data() {
            return {
                quantity: 1
            }
        },
        computed: {
            signComplete() {
                var complete = true;
                if(!this.$store.getters.selectedSize) {
                    complete = false;
                }
                if(!this.$store.state.selected.symbol) {
                    complete = false;
                }
                return complete;
            }
        },
        methods: {
            increaseQuantity(extraAmount) {
                this.quantity = Number(this.quantity) + Number(extraAmount);
            },
            decreaseQuantity(extraAmount) {
                if(this.quantity > 0) {
                    this.quantity = Number(this.quantity) - Number(extraAmount);
                }
                if( this.quantity - Number(extraAmount) < 0 ) {
                    this.quantity = 0;
                }
            },
            showSvgPreview() {
                var svg = document.getElementById('the_svg');
                this.$store.commit('setLifePreviewOpen', true);
            },
            isPriceBand(min, max) {

                if(this.quantity >= min && this.quantity <= max) {
                    return true;
                }

                return false;
            },
            createFinalSvgForPost() {

                // clone svg and clean up for form submission
                var svg = document.getElementById('the_svg');
                var cloneSvg = svg.cloneNode(true);
                cloneSvg.id = 'svg_sign';

                var clonedRulers = cloneSvg.getElementsByClassName('svg_ruler');
                var svgPanel = cloneSvg.getElementsByClassName('svg_panel')[0];
                var svgIcon = cloneSvg.getElementsByClassName('svg_icon')[0];
                var svgTextPanel = cloneSvg.getElementsByClassName('svg_text_panel')[0];

                if(clonedRulers) {
                    while(clonedRulers.length > 0){
                        clonedRulers[0].parentNode.removeChild(clonedRulers[0]);
                    }
                }

                var pX = svgPanel.getAttribute('x');
                var pY = svgPanel.getAttribute('y');
                svgPanel.setAttribute('x', 0);
                svgPanel.setAttribute('y', 0);

                var sIx = svgIcon.getAttribute('x');
                var sIy = svgIcon.getAttribute('y');
                svgIcon.setAttribute('x', sIx - pX);
                svgIcon.setAttribute('y', sIy - pY);

                var tPx = svgTextPanel.getAttribute('x');
                var tPy = svgTextPanel.getAttribute('y');
                svgTextPanel.setAttribute('x', tPx - pX);
                svgTextPanel.setAttribute('y', tPy - pY);

                // stringigy svg element to send
                var s = new XMLSerializer();
                var svgString = s.serializeToString(cloneSvg);

                return svgString;
            },
            addToBasket() {

                this.$store.commit('setError', null);

                // if(!this.signComplete) {
                //     this.$store.commit('setError', 'Please select symbol, size and text');
                //     return;
                // }
                //
                // if(this.quantity < 1) {
                //     this.$store.commit('setError', 'Quantity needs to be at least 1');
                //     return;
                // }

                this.$store.dispatch('checkProfanity', this.$store.getters.textAreas)
                    .then((res) => {

                        this.$store.commit('setLoading', false);

                        if(res.data === 1) {
                            // profanity found
                            this.$store.commit('setError', 'Profanity found, your order may be rejected');
                        }else{
                            // var svgString = this.createFinalSvgForPost();
                            // this.$store.dispatch('submitForm', svgString);
                            console.log('ALL GOOD YAY');
                        }

                    })
                    .catch((e) => {
                        this.$store.commit('setError', e);
                        this.$store.commit('setLoading', false);
                    });

            }
        }
    }

</script>

<style lang="scss">

    .summary-details {
        button {
            background: #459bf3;
            border: none;
            color: #fff;
            padding: 8px 16px;
            margin-top: 8px;
            width: 100%;
            border-radius: 5px;
        }
        input {
            border: solid 2px #459bf3;
            border-radius: 5px;
            width: 80px;
        }
        table {
            width: 100%;
            background: #fff;
            th, td {
                padding: 6px 8px;
            }
            th {
                background: #459bf3;
                color: #fff;
            }
            tr:nth-child(even) {
                background: #efefef;
                th {
                    background: #288bef;
                }
            }
        }
    }

    .panel_white {
        background: #fff;
        padding: 8px;
        margin-bottom: 16px;
        border-radius: 5px;
    }

    .panel {
        background: #c5ecf5;
        padding: 16px;
        .btn {
            width: 100%;
        }
    }

    .price_highlight {
        background: #b0deff !important;
        border: solid 2px #1a5692;
    }

</style>