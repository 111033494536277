<template>
    <div class="builder">

        <div class="text-center" v-if="$store.state.loading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="cols" v-else>

            <div class="left_col">

                <SignAttribute title="1. Select Material" :class="{loading: loading.materials}">
                    <select class="form-control mb-2" v-model="selectedMaterial" @change="forceRerender">
                        <option :value="m.id"
                                v-for="m in $store.state.options.materials">{{ m.label }}</option>
                    </select>
                    <div v-if="this.$store.getters.selectedMaterial">
                        <p>
                            Make a selection using the dropdown above.
                            Click here for information on all materials available
                        </p>
                        <hr>
                        <b>{{ this.$store.getters.selectedMaterial.label }}</b>
                        <p>{{ this.$store.getters.selectedMaterial.description }}</p>
                    </div>
                </SignAttribute>

                <SignAttribute title="2. Select Orientation" :class="{loading: loading.orientation}">
                    <ul class="option-list">
                        <li v-for="o in $store.state.options.orientations">
                            <div class="option-item" @click="selectOrientation(o.code)">
                                <div :class="'option-rectangle'"
                                     :style="boxStyle(o, (o.code === $store.state.selected.orientation))"></div>
                                <span class="option-item__label">{{ o.label }}</span>
                            </div>
                        </li>
                    </ul>
                </SignAttribute>

                <SignAttribute title="3. Select Size" :class="{loading: loading.sizes}">
                    <ul class="option-list">
                        <li v-for="o in $store.getters.sizeOptions">
                            <div class="option-item" @click="selectSize(o.id)">
                                <div class="option-rectangle" :style="boxStyle(o, (o.id === $store.state.selected.size))"></div>
                                <span class="option-item__label" v-html="sizeLabel(o.width, o.height)"></span>
                            </div>
                        </li>
                    </ul>
                </SignAttribute>

                <SignAttribute title="4. Pick A Symbol" :class="{loading: loading.symbols}">
                    <Symbols @symbolSelected="symbolSelected($event)"
                             @sizeSelected="sizeSelected($event)" />
                </SignAttribute>

                <SignAttribute title="5. Edit Custom Text" :class="{loading: loading.text}">
                    <TextEditor v-for="(textArea, i) in $store.getters.textAreas"
                                :index="i"
                                @textChanged="forceRerender()"
                                @textAreaRemoved="textAreaRemoved(i)" />
                    <div class="btn btn-accent mt-2" @click="addTextArea">New Textbox</div>
                </SignAttribute>

            </div>

            <div class="right_col" :class="{loading: isPreviewLoading }">

                <div class="sticky">
                    <SignPreview :compKey="compKey"
                                 @previewStartedLoading="loading.symbols = true"
                                 @previewStoppedLoading="loading.symbols = false" />
                    <SummaryDetails />
                </div>

            </div>

        </div>

        <div class="error" v-if="$store.state.error">{{ $store.state.error }}</div>

        <div class="life-preview" :style="{ display: ( $store.state.lifePreviewOpen ? '' : 'none' ) }">
            <div class="life-preview__img">
                <img src="../assets/preview_sign.png" />
                <div class="life-preview__cross"
                    @click="closeLifePreview">
                    <font-awesome-icon icon="times-circle" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import SignPreview from '../components/SignPreview';
    import SignAttribute from "../components/SignAttribute";
    import Symbols from "../components/Symbols";
    import SummaryDetails from "../components/SummaryDetails";
    import TextEditor from "../components/TextEditor";
    import axios from "axios";

    export default {
        name: 'Builder',
        components: {
            SummaryDetails,
            Symbols,
            SignPreview,
            SignAttribute,
            TextEditor
        },
        data() {
            return {
                compKey: 0,
                loading: {
                    materials: false,
                    orientation: false,
                    sizes: false,
                    symbols: false,
                    text: false
                }
            }
        },
        methods: {
            forceRerender() {
                return this.compKey += 1;
            },
            boxStyle(option, selected) {
                var style = {
                    width: (option.width * 0.2) + 'px',
                    height: (option.height * 0.2) + 'px',
                    borderColor: ( selected ? '#2482d6' : '#333' )
                };
                return style;
            },
            sizeLabel(w, h) {
                return '<span><b>' + w + ' x ' + h + '</b> mm</span>';
            },
            selectOrientation(code) {
                this.$store.commit('setSelectedOrientation', code);
                this.$store.commit('setDefaultSelectedSize');
                this.forceRerender();
            },
            selectSize(id) {
                this.$store.commit('setSelectedSize', id);
                this.forceRerender();
            },
            symbolSelected(id) {
                this.$store.commit('setSelectedSymbol', id);
                this.loadSymbolSvg();
            },
            sizeSelected(size) {
                this.$store.commit('setSelectedSymbolSize', size);
                this.forceRerender();
            },
            addTextArea() {
                this.$store.commit('addTextArea');
                this.forceRerender();
            },
            textAreaRemoved(i) {
                this.$store.commit('removeTextArea', i);
                this.forceRerender();
            },
            loadSymbolSvg() {

                var s = this.$store.getters.selectedSymbol;
                var symbol = document.getElementById('symbol');

                if(!symbol) {
                    return;
                }

                var self = this;

                var xhr = new XMLHttpRequest();
                xhr.open("GET", s.preview_image_url,false);
                xhr.overrideMimeType("image/svg+xml");
                xhr.onload = function(e) {
                    if(xhr.status === 200) {
                        symbol.innerHTML = xhr.responseXML.documentElement.innerHTML;
                    }else{
                        self.$store.commit('setError', 'Unable to load svg');
                    }
                }
                xhr.send("");

            },
            closeLifePreview() {
                this.$store.commit('setLifePreviewOpen', false);
            }
        },
        computed: {
            selectedMaterial: {
                get() {
                    var material = this.$store.getters.selectedMaterial;
                    if(material) {
                        return material.id;
                    }
                    return '';
                },
                set(value) {
                    this.$store.commit('setSelectedMaterial', value);
                    this.loading.materials = true;
                    this.loading.sizes = true;
                    const sizes = this.$store.dispatch('getSizes', this.$store.state.selected.material);
                    sizes.then(() => {
                        this.loading.materials = false;
                        this.loading.sizes = false;
                    });
                }
            },
            isPreviewLoading() {
                for (const [key, value] of Object.entries(this.loading)) {
                    if(value) {
                        return true;
                    }
                }
                return false;
            }
        },
        mounted() {

            var self = this;
            this.$store.commit('setLoading', true);
            const materials = this.$store.dispatch('getMaterials');
            materials.then(async () => {
                const sizes = this.$store.dispatch('getSizes', self.$store.state.selected.material);
                sizes.then(() => {
                    self.$store.commit('setLoading', false);
                });
            });
            this.loading.symbols = true;
            const symbols = this.$store.dispatch('getSymbols');
            symbols.then(() => {
                this.loading.symbols = false;
            });

        }
    }

</script>

<style lang="scss">

    .builder {
        padding-top: 80px;
        max-width: 984px;
        margin: 0px auto 20px auto;
    }

    .cols {
        /*overflow: hidden;*/
    }

    .left_col,
    .right_col {
        width: 488px;
    }

    .left_col { float: left; }
    .right_col { float: right; }

    .right_col {
        height: 2000px;
    }

    .option-list {
        list-style: none;
        margin: 0px;
        padding: 0px;
        li {
            display: inline-block;
            margin-right: 16px;
        }
    }

    .option-rectangle {
        border: solid 2px #333;
        cursor: pointer;
    }

    .option-item__label {
        font-size: 12px;
    }

    .loading {
        position: relative;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: url('../assets/loading.gif');
            background-color: rgba(255, 255, 255, 0.77);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60px;
        }
    }

    .sticky {
        position: sticky;
        top: 0;
    }

    .btn-accent {
        background: #f1c240;
        float: right;
        &:hover {
            background: #f9d470;
        }
    }

    .life-preview {
        position: fixed;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.8%);
        width: 100%;
        height: 100%;
        &__img {
            background: #fff;
            padding: 10px;
            width: 720px;
            margin: 60px auto;
            position: relative;
            img {
                width: 700px;
            }
        }
        &__cross {
            color: #1159a2;
            background: #fff;
            border-radius: 100%;
            font-size: 38px;
            width: 38px;
            height: 38px;
            line-height: 38px;
            position: absolute;
            top: -19px;
            right: -19px;
            cursor: pointer;
        }
    }

</style>