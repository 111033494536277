<template>

    <div class="text-editor">
        <div class="actions">
            <div class="actions__btn"
                 :class="{ 'is-selected': isSelected('left') }"
                 @click="selectAlignment('left')">
                <font-awesome-icon icon="align-left" />
            </div>
            <div class="actions__btn"
                 :class="{ 'is-selected': isSelected('center') }"
                 @click="selectAlignment('center')">
                <font-awesome-icon icon="align-center" />
            </div>
            <div class="actions__btn"
                 :class="{ 'is-selected': isSelected('right') }"
                 @click="selectAlignment('right')">
                <font-awesome-icon icon="align-right" />
            </div>
        </div>
        <div class="actions">
            <div class="actions__btn" @click="setPosition(true)">
                <font-awesome-icon icon="arrow-up" />
            </div>
            <div class="actions__btn" @click="setPosition(false)">
                <font-awesome-icon icon="arrow-down" />
            </div>
        </div>
        <div class="actions">
            <div class="actions__btn" @click="changeFontSize(false)">
                <font-awesome-icon icon="font" style="font-size: 12px;" />
                <font-awesome-icon icon="arrow-up" style="font-size: 14px;" />
            </div>
            <div class="actions__btn" @click="changeFontSize(true)">
                <font-awesome-icon icon="font" style="font-size: 12px;" />
                <font-awesome-icon icon="arrow-down" style="font-size: 14px;" />
            </div>
        </div>
        <div class="actions float-right mr-0" v-if="$store.state.text.areas.length > 1">
            <div class="actions__btn bg-red mr-0" @click="remove">
                <font-awesome-icon icon="trash-alt" />
            </div>
        </div>
        <textarea v-model="text" @keyup="$emit('textChanged')"></textarea>
    </div>

</template>

<script>

    export default {
        name: 'TextEditor',
        props: ['index', 'textArea'],
        data() {
            return {
                textSize: 14,
                textAlignment: 'center',
                textPosition: 0
            }
        },
        computed: {
            text: {
                get() {
                    return this.$store.getters.text(this.index);
                },
                set(val) {
                    this.$store.commit('setText', {
                        index: this.index,
                        text: val
                    });
                }
            }
        },
        methods: {
            isSelected(alignment) {
                if(alignment === this.$store.state.text.areas[this.index].alignment) {
                    return true;
                }
                return false;
            },
            remove() {
                this.$emit('textAreaRemoved', this.index);
            },
            selectAlignment(alignment) {
                this.$store.commit('setAlignment', {
                    alignment,
                    index: this.index
                });
                this.$emit('textChanged');
            },
            setPosition(up) {
                this.$store.commit('setTextPosition', {
                    index: this.index,
                    up: up
                });
                this.$emit('textChanged');
            },
            changeFontSize(larger) {
                this.$store.commit('setFontSize', {
                    index: this.index,
                    larger: larger
                });
                this.$emit('textChanged');
            }
        }
    }

</script>

<style lang="scss">

    .text-editor {
        background: #fff;
        padding: 8px;
        border-radius: 3px;
        margin-bottom: 16px;
        textarea {
            width: 100%;
            margin-top: 10px;
            margin-bottom: -5px;
            border: solid 2px #a9b6b7;
            border-radius: 3px;
            padding: 4px 10px;
        }
    }

    .actions {
        display: inline-block;
        margin-right: 10px;
        &__btn {
            display: inline-block;
            background: #c7d1d2;
            padding: 5px 4px 4px 4px;
            width: 34px;
            height: 34px;
            margin-right: 8px;
            text-align: center;
            border-radius: 3px;
            cursor: pointer;
            &:hover,
            &:active {
                background: #d9e5e6;
            }
            &.is-selected {
                background: #5ca4ab;
                color: #fff;
                &:hover {
                    background: #5ca4ab;
                }
            }
        }
    }

    .bg-red {
        background: #ca4343;
        color: #fff;
        &:hover {
            background: #ca6060;
        }
    }

</style>