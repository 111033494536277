class RulerPathBuilder {

    buildPath(lines) {
        var pathString = "";
        lines.forEach((line) => {
            pathString += "M" + line.s.x + ' ' + line.s.y;
            pathString += "L" + line.e.x + ' ' + line.e.y;
        });
        return pathString;
    }

    buildX(pAtt) {

        var lines = [];
        var panelBottom = Number(pAtt.y) + Number(pAtt.height);
        var panelRight = Number(pAtt.width) + Number(pAtt.x);
        lines.push({
            s: {x: Number(pAtt.x), y: panelBottom + this.rulerSpace},
            e: {x: Number(pAtt.x), y: panelBottom + this.rulerSpace + this.rulerDepth}
        });
        lines.push({
            s: {x: Number(pAtt.x), y: panelBottom + this.rulerSpace + this.rulerDepth},
            e: {x: panelRight, y: panelBottom + this.rulerSpace + this.rulerDepth}
        });
        lines.push({
            s: {x: panelRight, y: panelBottom + this.rulerSpace + this.rulerDepth},
            e: {x: panelRight, y: panelBottom + this.rulerSpace}
        });
        return this.buildPath(lines);

    }

    buildY(pAtt) {

        var lines = [];
        var panelBottom = Number(pAtt.y) + Number(pAtt.height);
        var panelRight = Number(pAtt.width) + Number(pAtt.x);
        lines.push({
            s: {x: panelRight + this.rulerSpace, y: panelBottom},
            e: {x: panelRight + this.rulerSpace + this.rulerDepth, y: panelBottom}
        });
        lines.push({
            s: {x: panelRight + this.rulerSpace + this.rulerDepth, y: panelBottom},
            e: {x: panelRight + this.rulerSpace + this.rulerDepth, y: Number(pAtt.y)}
        });
        lines.push({
            s: {x: panelRight + this.rulerSpace + this.rulerDepth, y: Number(pAtt.y)},
            e: {x: panelRight + this.rulerSpace, y: Number(pAtt.y)}
        });
        return this.buildPath(lines);

    }

    constructor(rulerSpace, rulerDepth) {
        this.rulerSpace = rulerSpace;
        this.rulerDepth = rulerDepth;
    }

}

export default RulerPathBuilder;