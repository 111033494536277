
var PreviewCalculator = {

    getPercentageChange(dimension, maxPixels){
        if(dimension > maxPixels){
            // size is bigger than the preview window
            return 0 - (dimension - maxPixels) * ( 100 / dimension );
        }else{
            // size is smaller than the preview window
            return ( maxPixels - dimension ) * (100 / dimension);
        }
    },

    getPercentageOf(percentage, size){
        return (size / 100) * percentage;
    },

    getOrientation(w, h){
        return ( w > h ? 'landscape' : 'portrait' );
    },

    getAdjustedDimension(dimension, change){
        return dimension + this.getPercentageOf(dimension, change);
    },

    getCenterDimension(max, actual) {
        return (max - actual) / 2;
    },

    getCoordsToCenter(width, height) {
        var spaceX = this.previewWidth - width;
        var spaceY = this.previewHeight - height;
        return {
            x: spaceX / 2,
            y: spaceY / 2
        }
    },

    getSizeInPixels(width, height){

        if(width === 0 && height === 0) {
            return {width: 0, height: 0};
        }

        var orientation = this.getOrientation(width, height);

        if(orientation === 'portrait'){
            // set the height
            var change = this.getPercentageChange(height, this.maxHeight);
            var newWidth = this.getAdjustedDimension(width, change);

            return { width: Math.floor(newWidth), height: Math.floor(this.maxHeight) };
        }

        if(orientation === 'landscape'){
            // set the width
            var change = this.getPercentageChange(width, this.maxWidth);
            var newHeight = this.getAdjustedDimension(height, change);
            return { width: Math.floor(this.maxWidth), height: Math.floor(newHeight) };
        }

    },

    set(previewWidth, previewHeight, padding) {
        this.previewWidth = previewWidth;
        this.previewHeight = previewHeight;
        this.maxWidth = previewWidth - (padding * 2);
        this.maxHeight = previewHeight - (padding * 2);
        this.padding = padding;
    }

    // constructor(previewWidth, previewHeight, padding) {
    //     this.previewWidth = previewWidth;
    //     this.previewHeight = previewHeight;
    //     this.maxWidth = previewWidth - (padding * 2);
    //     this.maxHeight = previewHeight - (padding * 2);
    //     this.padding = padding;
    // }

}

export default PreviewCalculator;