import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faArrowUp,
  faArrowDown,
  faTrashAlt,
  faTimesCircle,
  faFont
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAlignLeft)
library.add(faAlignRight)
library.add(faAlignCenter)
library.add(faArrowUp)
library.add(faArrowDown)
library.add(faTrashAlt)
library.add(faTimesCircle)
library.add(faFont)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
